

















































































































































































































































































































































































import Vue, { PropType } from "vue";

import { get_color_by_name } from "@/utils/colors";
import ExtraDetails from "@/components/shared/view_job_details/ExtraDetails.vue";
import JobDescriptionCard from "@/components/shared/view_job_details/JobDescriptionCard.vue";
import SkillsWithGroups from "@/components/shared/SkillsWithGroups.vue";
import { mapActions, mapMutations } from "vuex";
import { FETCH_SKILLS_GROUP } from "@/store/modules/common/constants";
import { ViewJob } from "@/interfaces/recruiter/jobs/job_listing/view_job";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import CandidateEmployeeCard from "@/components/recruiter/CandidateEmployeeCard.vue";
import { format_overall_score } from "@/utils/global";
import {
  JobsWithCandidates,
  MatchingCandidatesStatus,
  SearchedCandidate
} from "@/store/modules/recruiter/interfaces";
import {
  FETCH_MATCHING_CANDIDATES,
  GET_FAVORITE_CANDIDATES
} from "@/store/modules/recruiter/constants";
import { GetFavoriteCandidates } from "@/interfaces/responses/favorite_candidates";
import ViewJobLoading from "@/components/recruiter/jobs/ViewJobLoading.vue";
import AddCandidate from "@/components/recruiter/candidates/AddCandidate.vue";
export default Vue.extend({
  name: "ViewJob",
  components: {
    ViewJobLoading,
    CandidateEmployeeCard,
    DataNotFound,
    SkillsWithGroups,
    JobDescriptionCard,
    ExtraDetails,
    AddCandidate
  },
  data(): ViewJob & {
    add_candidate: boolean;
    all_candidates: SearchedCandidate[];
  } {
    return {
      fav_loading: true,
      loading: true,
      job: null,
      view_all_matching_candidates: false,
      view_all_shortlisted_candidates: false,
      calculate_favorites: false,
      add_candidate: false,
      all_candidates: []
    };
  },
  props: {
    job_to_view: {
      type: Object as PropType<JobsWithCandidates>,
      required: true
    }
  },
  async created() {
    await this.init_data();
  },
  async updated() {
    if (!this.job_to_view.candidates_loading && !this.calculate_favorites) {
      this.calculate_favorites = true;
      this.fav_loading = true;
      if (
        this.job &&
        this.job.matching_candidates &&
        this.job.matching_candidates.length > 0
      ) {
        this.job.matching_candidates = await this.process_favorites();
        this.all_candidates = this.job.matching_candidates;
      }
      this.fav_loading = false;
    }
  },
  computed: {
    shortlisted_candidates(): SearchedCandidate[] {
      if (this.job) {
        return this.all_candidates
          .filter((candidate: SearchedCandidate) => {
            if (candidate.score) return candidate.score >= 0.2;
          })
          .filter(
            (item: SearchedCandidate) =>
              item.matching_candidate_status &&
              item.matching_candidate_status ===
                MatchingCandidatesStatus.SHORTLISTED
          );
      }
      return [];
    },
    matching_candidates(): SearchedCandidate[] {
      if (this.job) {
        return this.all_candidates
          .filter((candidate: SearchedCandidate) => {
            if (candidate.score) return candidate.score >= 0.2;
          })
          .filter(
            (item: SearchedCandidate) =>
              item?.matching_candidate_status === null
          );
      }
      return [];
    }
  },
  methods: {
    format_overall_score,
    ...mapActions("common", {
      fetch_skill_group: FETCH_SKILLS_GROUP
    }),
    ...mapActions("recruiter", {
      fetch_favorite_candidates_by_job: GET_FAVORITE_CANDIDATES,
      fetch_matching_candidates: FETCH_MATCHING_CANDIDATES
    }),
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    transform_edit_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("primary");
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    },
    /**
     * Function to initialize data
     * Set local job variable
     * Add https in front of url if not exist
     * If skills list is of type string => parse skills
     * Get skills group => back to previous page => if failed to fetch
     * Fetching favorite candiates against job
     */
    async init_data() {
      this.loading = true;
      this.fav_loading = true;
      this.job = this.job_to_view;
      // Checking url starts with https => if not append https in start of url
      if (this.job.url && !this.job.url.startsWith("http")) {
        this.job.url = "https://" + this.job.url;
      }
      // Parsing skills
      if (this.job.skills_list && typeof this.job.skills_list === "string") {
        let skills: string | string[] = [];
        skills = JSON.parse(this.job.skills_list);
        if (typeof skills === "string") skills = eval(skills);
        // Fetching skills with group
        const skills_with_group = await this.fetch_skill_group(skills);
        if (skills_with_group) {
          this.job.skills_list = skills_with_group;
          if (
            !this.job.candidates_loading &&
            this.job.matching_candidates &&
            this.job.matching_candidates.length > 0
          ) {
            // Fetching favorites
            this.fav_loading = true;
            this.job.matching_candidates = await this.process_favorites();
            this.all_candidates = this.job.matching_candidates;
            this.calculate_favorites = true;
          }
        } else {
          this.root_error(this.$t("errors.internal"));
          this.$emit("reset_view_state");
        }
      }
      this.fav_loading = false;
      await this.fetch_matching_candidate();
      this.loading = false;
    },
    /**
     * Function to fetch favorites against job
     * @return CandidatesWithFavorite[]
     */
    async process_favorites(): Promise<SearchedCandidate[]> {
      const favorites = await this.fetch_favorite_candidates_by_job(
        this.job_to_view.jid
      );
      const res = this.job_to_view.matching_candidates.map(
        (obj: SearchedCandidate) => ({
          ...obj,
          is_favorite: false
        })
      );
      return res.map((obj1: SearchedCandidate) => {
        let obj2 = favorites.find(
          (obj2: GetFavoriteCandidates) =>
            obj1.candidate_id === obj2.candidate_id
        );
        if (obj2) {
          obj1.is_favorite = true;
        }
        return obj1;
      });
    },
    async edit_job() {
      await this.$router.push(`/recruiter/jobs/${this.job_to_view.jid}/edit`);
    },
    add_new_candidate() {
      this.add_candidate = true;
    },
    async fetch_matching_candidate() {
      this.fav_loading = true;
      this.add_candidate = false;
      if (this.job) {
        const matching_candidates = await this.fetch_matching_candidates({
          job_ids: [this.job.jid]
        });
        if (matching_candidates) {
          this.job.matching_candidates = matching_candidates.results || [];
          this.all_candidates = this.job.matching_candidates;
        }
      }
      this.fav_loading = false;
    }
  }
});
