































































































































































import Vue from "vue";
import UploadBulkResume from "@/components/shared/UploadBulkResume..vue";
import { mapActions } from "vuex";
import { ADD_CANDIDATE } from "@/store/modules/recruiter/constants";

export default Vue.extend({
  name: "AddCandidate",
  components: {
    UploadBulkResume
  },
  data() {
    return {
      dialog: true,
      animation: require("@/assets/illustrations/analyzing.json"),
      loading: false,
      step: 2
    };
  },
  created() {
    this.animation = JSON.stringify(this.animation);
  },
  methods: {
    ...mapActions("recruiter", {
      add_resume: ADD_CANDIDATE
    }),
    async add_candidate(filesObject: { value: File[] }) {
      this.loading = true;
      const files = filesObject.value;
      if (files.length > 0) {
        const obj: FormData = new FormData();
        obj.append("candidate_resume", files[0]);
        try {
          await this.add_resume(obj);
          this.loading = false;
          this.$emit("on_result");
        } catch (error) {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    on_next() {
      this.step = 2;
    }
  }
});
