



















import Vue from "vue";
import { get_name_initials } from "@/utils/global";

export default Vue.extend({
  name: "ProfilePictureDirect",
  props: {
    avatar_uri: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    // For using .avatar class
    // By default using .user-details-avatar class
    extra_avatar_class: {
      type: Boolean,
      default: false
    },
    mobile_size: {
      type: Number,
      default: 70
    },
    desktop_size: {
      type: Number,
      default: 140
    }
  },
  methods: {
    get_name_initials
  }
});
