






















































































































































































































































import Vue, { PropType } from "vue";
import { CandidateEmployeeCard } from "@/interfaces/recruiter/candidate_employee_card";
import { TranslateResult } from "vue-i18n";
import {
  get_color_by_matching_score,
  get_random_color,
  get_random_color_hash
} from "@/utils/colors";
import { fetch_latest_work_history } from "@/utils/cv_parser";
import { clean_string, get_name_initials } from "@/utils/global";
import { SocialLinks } from "@/store/modules/onboarding/interfaces";
import { mapActions, mapMutations } from "vuex";
import {
  CANDIDATE_VIEW_PAYLOAD,
  UPDATE_FAVORITE_CANDIDATE,
  UPDATE_MATCHING_CANDIDATE_STATUS
} from "@/store/modules/recruiter/constants";
import {
  MatchingCandidatesStatus,
  SearchedCandidate,
  UpdateFavoriteCandidatePayload
} from "@/store/modules/recruiter/interfaces";
import ProfilePictureDirect from "@/components/shared/view_profile/ProfilePictureDirect.vue";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";
export default Vue.extend({
  name: "CandidateEmployeeCard",
  components: { ProfilePictureDirect },
  data(): CandidateEmployeeCard & {
    shortlist_loading: boolean;
  } {
    return {
      linkedin_icon: require("@/assets/logos/linkedin-3.svg"),
      twitter_icon: require("@/assets/logos/twitter.svg"),
      instagram_icon: require("@/assets/logos/instagram.svg"),
      messages_icon: require("@/assets/icons/bold/messages.svg"),
      font_color: "",
      background_color: "",
      color_code: "",
      favorite: false,
      shortlist_loading: false
    };
  },
  mounted() {
    const color = get_color_by_matching_score(this.data.score ?? 0); // Get random color
    this.font_color = `color: ${color}`;
    this.background_color = `background: ${color}`;
    this.color_code = color;
    this.favorite = this.data.is_favorite ?? false;
  },
  computed: {
    MatchingCandidatesStatus() {
      return MatchingCandidatesStatus;
    }
  },
  props: {
    // For displaying user_experience/job skill match percentage in card header
    // For displaying user social media link in card footer
    show_experience: {
      type: Boolean,
      default: true
    },
    // For employees type
    employees_type: {
      type: Number // 1 => registered employees, 2 => unregistered employees
    },
    // Hold title when user want to display job skill match percentage
    // Use with match title
    match_title: {
      type: String as PropType<TranslateResult>
    },
    // Hold job skill match percentage
    // Use with match title
    match_value: {
      type: String
    },
    // For assigning different colors to each card
    unique_color: {
      type: Boolean,
      default: false
    },
    // Contain SearchedCandidate Data set
    data: {
      type: Object as PropType<SearchedCandidate>,
      required: true
    },
    // Used for fetching favorite candidates
    // Used when user want to view specific candidate profile
    job_id: {
      type: Number
    },
    disable_favorites_action: {
      type: Boolean,
      default: false
    },
    show_shortlisted_button: {
      type: Boolean,
      default: false
    },
    direct_to_candidate_detail: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations("recruiter", {
      set_candidate_view_payload: CANDIDATE_VIEW_PAYLOAD
    }),
    ...mapActions("recruiter", {
      update_favorite_status: UPDATE_FAVORITE_CANDIDATE,
      update_matching_candidate_status: UPDATE_MATCHING_CANDIDATE_STATUS
    }),
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION
    }),
    get_random_color_hash,
    fetch_latest_work_history,
    get_random_color,
    // Function to return experience
    get_experience(): string {
      if (
        !this.data.upload_response.profile ||
        !this.data.upload_response.profile.total_experience
      )
        return "";
      return this.data.upload_response.profile.total_experience + " ";
    },
    // Function to return name initials
    get_initials(): string {
      return get_name_initials(this.get_name());
    },
    // Function to return name
    get_name(): string {
      const profile = this.data.upload_response.profile;
      if (!profile || Object.keys(profile.personal_details).length === 0)
        return "";
      return Object.values(profile.personal_details.name)
        .filter((n) => n !== "N/A" && n !== " ")
        .join(" ");
    },
    // Function to return latest work experience
    get_work(): string {
      const profile = this.data.upload_response.profile;
      if (
        profile &&
        profile.work_history_detailed &&
        Object.values(profile.work_history_detailed).length > 0
      )
        return Object.values(profile.work_history_detailed)[0].job_title;
      return "";
    },
    async view_candidate() {
      sessionStorage.setItem("job", this.job_id ? this.job_id.toString() : "");
      sessionStorage.setItem("user", this.data.candidate_id.toString());
      sessionStorage.setItem("upload", this.data.upload_response_id);
      if (this.show_shortlisted_button)
        await this.$router.push("/recruiter/talent-pool/profile");
      else if (!this.direct_to_candidate_detail)
        await this.$router.push("/recruiter/candidates/profile");
      else await this.$router.push("/recruiter/talent-pool/profile");
    },
    async navigate_to(to: string) {
      const social_link = this.data.social_links?.find(
        (val: SocialLinks) => clean_string(val.platform) === clean_string(to)
      );
      if (social_link && social_link.url) {
        const link = !social_link.url.startsWith("http")
          ? "https://" + social_link.url
          : social_link.url;
        if (link) {
          window.open(link, "_blank");
        }
      }
    },
    async toggle_fav_status() {
      const payload: UpdateFavoriteCandidatePayload = {
        job_id: this.job_id,
        candidate_id: this.data.candidate_id,
        is_favorite: !this.data.is_favorite
      };
      const res = await this.update_favorite_status(payload);
      if (res) this.data.is_favorite = !this.data.is_favorite;
      this.favorite = this.data.is_favorite ?? false;
    },
    async shortlist_candidate() {
      this.shortlist_loading = true;
      const payload = {
        job_id: this.job_id,
        candidate_id: this.data.candidate_id,
        status: MatchingCandidatesStatus.SHORTLISTED
      };
      const res = await this.update_matching_candidate_status(payload);
      if (res) {
        this.root_notification(
          `${this.get_name()} is Shortlisted, For next step check Candidate tab`
        );
        this.$emit("on_shortlist", this.data.candidate_id);
      }
      this.shortlist_loading = false;
    },
    async unshortlist_candidate() {
      this.shortlist_loading = true;
      const payload = {
        job_id: this.job_id,
        candidate_id: this.data.candidate_id,
        status: "REMOVE"
      };
      const res = await this.update_matching_candidate_status(payload);
      if (res) {
        this.root_notification(`${this.get_name()} is Dissapproved`);
        this.$emit("on_shortlist", this.data.candidate_id);
      }
      this.shortlist_loading = false;
    },
    get_icon(text: string) {
      switch (text) {
        case "SHORTLISTED":
          return "shortlisted.svg";
        case "INTERVIEWED":
          return "keyboard-open.svg";
        case "ONBOARD":
          return "glass.svg";
        case "JOINED":
          return "keyboard-open.svg";
        case "REJECTED":
          return "close-circle.svg";
        default:
          return "keyboard-open.svg";
      }
    }
  }
});
